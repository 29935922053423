import Head from 'next/head'
import { createGlobalStyle, css } from 'styled-components'

import { trimFontSpaces, configureHead } from '/components/utils'

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

function backgroundRadial(color) {
  const result = hexToRgb(color)
  if (result) {
    const fade = `rgba(${result.r}, ${result.g}, ${result.b}, 0.5)`
    return `
      background-color: ${color};
      background: radial-gradient(circle, ${fade} 0%, ${color} 70%);
    `
  } else {
    return `
      background-color: ${color};
    `
  }
}

function backgroundLinear(color, offset = 10) {
  const result = hexToRgb(color)
  if (result) {
    const inside = `rgba(${result.r}, ${result.g}, ${result.b}, 0.1)`
    const outside = `rgba(${result.r}, ${result.g}, ${result.b}, 0.3)`
    return `
      background-color: ${inside};
      background-image: linear-gradient(45deg, ${outside} 0%, ${outside} ${offset}%, ${inside} ${offset}%, ${inside} ${
      100 - offset
    }%, ${outside} ${100 - offset}%);
    `
  } else {
    return `
      background-color: ${color};
    `
  }
}

const sizes = {
  largeDesktop: 1168,
  desktop: 992,
  tablet: 768,
  phone: 576
}

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const buildTheme = (siteData) => {
  // build theme for specific enterprise
  const base = {
    colors: {
      primary: siteData?.theme?.primary_color,
      primary_text: siteData?.theme?.primary_color_text,
      secondary: siteData?.theme?.secondary_color,
      secondary_text: siteData?.theme?.secondary_color_text,
      tertiary: siteData?.theme?.tertiary_color,
      tertiary_text: siteData?.theme?.tertiary_color_text,
      backgroundCss: backgroundRadial(siteData?.theme?.primary_color),
      backgroundLinear: backgroundLinear,

      font_color: siteData?.theme?.font_color,
      background_color: siteData?.theme?.background_color,
      nav_background_color: siteData?.theme?.nav_background_color,
      nav_color: siteData?.theme?.nav_color,

      white: '#fff',
      black: '#0e2240',
      darkGrey: '#474747',
      grey: '#999',
      lightGrey: '#e8e8e8',
      superLightGrey: '#f7f8fa',
      borderGrey: '#e6e6e6',
      gold: '#edab1e',
      success: '#5cb85c',
      danger: '#d43f3a',
      lightBlue: '#0d70cc',
      darkBlue: '#022241',

      tier1: '#999',
      tier2: '#6C7787',
      tier3: '#324B75',
      tier4: '#edab1e',

      adminBlue: '#0d70cc',
      adminSuccess: '#5cb85c',
      adminDanger: '#d43f3a',
      adminInfo: '#17a2b8',
      adminWarning: '#ffc107',
      adminDelinquent: '#ff6161',

      adminBorder: '#eee'
    },

    inputs: {
      border: '#eee',
      border_radius: '10px',
      padding: '30px 15px'
    },

    fontFamilies: {
      headerFontFamily: siteData?.theme?.header_font_family?.name,
      bodyFontFamily: siteData?.theme?.body_font_family?.name
    },

    fontSizes: {
      xxlarge: '4rem',
      xlarge: '3.5rem',
      large: '2rem',
      medium: '1.3rem',
      normal: '1.2rem',
      small: '0.9rem',
      xsmall: '0.8rem'
    },

    breakpoints: {
      small: '767px',
      medium: '992px',
      large: '1200px'
    },
    media
  }
  return base
}

export const buildAdminTheme = (siteData) => {
  return {
    colors: {
      primary: siteData?.theme?.primary_color,
      secondary: siteData?.theme?.secondary_color,
      tertiary: siteData?.theme?.tertiary_color,

      white: '#fff',
      black: '#0e2240',
      darkGrey: '#474747',
      grey: '#999',
      lightGrey: '#e8e8e8',
      superLightGrey: '#f7f8fa',
      borderGrey: '#e6e6e6',
      gold: '#edab1e',
      success: '#5cb85c',
      danger: '#d43f3a',
      lightBlue: '#0d70cc',
      darkBlue: '#022241',

      adminBlue: '#0d70cc',
      adminSuccess: '#5cb85c',
      adminDanger: '#d43f3a',
      adminInfo: '#17a2b8',
      adminWarning: '#ffc107',
      adminDelinquent: '#ff6161',

      adminBorder: '#eee'
    },

    fontSizes: {
      xxlarge: '4rem',
      xlarge: '3.5rem',
      large: '3rem',
      medium: '1.3rem',
      normal: '1.2rem',
      small: '0.9rem',
      xsmall: '0.8rem'
    },

    breakpoints: {
      small: '767px',
      medium: '992px',
      large: '1200px'
    },

    navbar: {
      height: 80
    },
    media
  }
}

export const buildGlobalStyle = (siteData) => {
  let navbarHeight = '80px'
  let marketplaceContentPadded = '150px'
  let ctaFontSize = '14px'
  if (siteData.slug === 'worth-media') {
    navbarHeight = 0
    marketplaceContentPadded = 0
  } else if (siteData.slug === 'ffr') {
    navbarHeight = '85px'
    marketplaceContentPadded = 0
  } else if ((siteData?.subnav_items?.content || '').length > 0) {
    navbarHeight = '142px'
    marketplaceContentPadded = '140px'
    ctaFontSize = '18px'
  } else if (siteData.slug === 'earthlink') {
    navbarHeight = '104px'
    marketplaceContentPadded = '104px'
    ctaFontSize = '18px'
  }
  return createGlobalStyle`
    :root {
      --chalice-navbar-height: ${navbarHeight};
      --chalice-marketplace-padding: ${marketplaceContentPadded};
      --chalice-cta-font-size: ${ctaFontSize};
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
      -moz-appearance:textfield; /* Firefox */
    }

    body {
      font-family: '${siteData?.theme?.body_font_family?.name}', 'Montserrat';
      background: ${(props) =>
        props.theme.colors.background_color || props.theme.colors.white};
      color: ${(props) =>
        props.theme.colors.font_color || props.theme.colors.black};
    }

    h1, h2 {
      font-family: '${siteData?.theme?.header_font_family?.name}', 'Montserrat';
      color: ${(props) =>
        props.theme.colors.font_color || props.theme.colors.black};
    }

    form { }

    input::placeholder, input::-moz-placeholder {
      font-family: '${
        siteData?.theme?.body_font_family?.name
      }', 'Montserrat' !important;
      color: ${(props) =>
        props.theme.colors.font_color || props.theme.colors.black};
    }

    input {
      border-radius: ${
        siteData?.theme?.inputs?.border_radius || '5px'
      } !important;
      border: ${siteData?.theme?.inputs?.border || '#ddd'} solid 1px;
      padding: ${siteData?.theme?.inputs?.padding || '5px 10px'} !important;
      font-size: 1rem !important;
      color: ${(props) =>
        props.theme.colors.font_color || props.theme.colors.black} !important;
      background-color: ${(props) =>
        props.theme.colors.background_color ||
        props.theme.colors.white} !important;
    }

    select {
      border-radius: ${
        siteData?.theme?.inputs?.border_radius || '5px'
      } !important;
      border: ${siteData?.theme?.inputs?.border || '#ddd'} solid 1px !important;
      padding: ${siteData?.theme?.inputs?.padding || '5px 10px'} !important;
      font-size: 1rem !important;
      color: ${(props) =>
        props.theme.colors.font_color || props.theme.colors.black} !important;
      background-color: ${(props) =>
        props.theme.colors.background_color ||
        props.theme.colors.white} !important;
    }

    input[type='checkbox'] { }

    .well, .panel {
      background-color: ${(props) =>
        props.theme.colors.background_color ||
        props.theme.colors.white} !important;
    }
  `
}

export const buildHead = (siteData) => {
  let headerFontFamilyFormatted, bodyFontFamilyFormatted
  if (
    siteData?.theme?.header_font_family ||
    siteData?.theme?.body_font_family
  ) {
    const fonts = trimFontSpaces(siteData.theme)
    headerFontFamilyFormatted = fonts.headerFontName
    bodyFontFamilyFormatted = fonts.bodyFontName
  }

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <link rel="manifest" href="/manifest.json" />
      <link rel="apple-touch-icon" href="/assets/images/logo-192.png"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href={`https://fonts.googleapis.com/css2?family=${bodyFontFamilyFormatted}:wght@300;400;500;700;900&display=swap`}
        rel="stylesheet"
      />
      <link
        href={`https://fonts.googleapis.com/css2?family=${headerFontFamilyFormatted}:wght@300;400;500;700;900&display=swap`}
        rel="stylesheet"
      />
      <meta
        name="theme-color"
        content={siteData?.theme?.primary_color || '#317efb'}
      />
      {/* headContentParsed */}
      {configureHead(siteData).map((script) => script)}
    </Head>
  )
}
