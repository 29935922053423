import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'

const BannerAd = ({ banner }) => {
  const divRef = useRef(null)

  useEffect(() => {
    if (banner?.type === 'HTML' && banner?.custom_html) {
      const slotHtml = document
        .createRange()
        .createContextualFragment(banner.custom_html) // Create a 'tiny' document and parse the html string
      divRef.current.innerHTML = '' // Clear the container
      divRef.current.appendChild(slotHtml) // Append the new content
    }
  }, [banner])

  if (banner?.type === 'Image') {
    return (
      <a href={banner.url} target="_blank" rel="noreferrer">
        <BannerImage
          src={banner.media?.media}
          alt={
            banner.media?.description ||
            banner.media?.name ||
            'banner advertisement image'
          }
        />
      </a>
    )
  }

  if (banner?.type === 'HTML') {
    return <div ref={divRef} />
  }

  return null
}

BannerAd.propTypes = {
  placement: PropTypes.oneOf(['Marketplace', 'Footer', 'Product'])
}

const BannerImage = styled.img`
  width: 100%;
  max-height: 500px;
`

export default BannerAd
